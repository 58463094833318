<template>
  <SmoothModal
    :model-value="isModalOpen"
    :has-header-divider="false"
    @update:model-value="onUpdate"
  >
    <template #title>
      {{ modalTitle }}
    </template>
    <template #description>
      <AppText opacity="0.5">
        {{ isBulkPreviewStage ? t('merchantClients.clientCreateModal.subtitle.bulk') : t('merchantClients.clientCreateModal.subtitle.single') }}
      </AppText>
    </template>
    <FRadioButton
      v-if="!isBulkPreviewStage"
      v-model="type"
      :options="creationTypes"
      style="margin-top: 2px;"
      class="mb-4"
    />
    <div class="full-height">
      <CreateSingle v-if="isCreateSingle" @update-data="onUpdateData" />
      <CreateBulk
        v-if="!isCreateSingle"
        v-model:is-preview="isPreviewStage"
        @update-data="onUpdateData"
      />
    </div>
  </SmoothModal>
</template>

<script>
import { computed, ref } from 'vue';

import SmoothModal from '@/components/SmoothModal';

import { useModel } from '@/composables/useModel';
import { i18n } from '@/plugins/localization';

import { creationTypes as types } from '../data';

import CreateBulk from './CreateBulk.vue';
import CreateSingle from './CreateSingle.vue';

export default {
  name: 'CreateClientModal',
  components: {
    SmoothModal,
    CreateSingle,
    CreateBulk,
  },
  emits: ['update-data'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const { value: isModalOpen } = useModel(props, emit);

    const creationTypes = computed(() => types.map((type) => ({
      text: t(`merchantClients.clientCreateModal.tab.${type.value}`),
      value: type.value,
    })));

    const type = ref(creationTypes.value[0].value);
    const isPreviewStage = ref(false);

    const isCreateSingle = computed(() => type.value === creationTypes.value[0].value);
    const isBulkPreviewStage = computed(() => isPreviewStage.value && type.value === creationTypes.value[1].value);

    const modalTitle = computed(() => {
      if (type.value === 'single') {
        return t('merchantClients.clientCreateModal.title.add');
      }
      return isPreviewStage.value ? t('merchantClients.clientCreateModal.title.review') : t('merchantClients.clientCreateModal.title.import');
    });

    const onUpdateData = () => {
      emit('update-data');
      isModalOpen.value = false;
    };

    const onUpdate = () => {
      type.value = creationTypes.value[0].value;
      isPreviewStage.value = false;
    };

    return {
      isModalOpen,
      isPreviewStage,
      isBulkPreviewStage,

      type,
      isCreateSingle,
      creationTypes,
      modalTitle,

      onUpdateData,
      onUpdate,
    };
  },
};
</script>
