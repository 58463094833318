<template>
  <div class="bulk full-height">
    <template v-if="!isPreview">
      <FInput
        v-model="data"
        class="bulk-textarea"
        type="textarea"
        :placeholder="placeholder"
        :is-full-width="false"
        mask="all"
        name="clients"
        :has-error="!isBulkInputValid"
        has-force-error
        :maxlength="524288"

        @keypress.ctrl.enter.stop="previewClients"
      />
    </template>
    <template v-if="isPreview">
      <AppText v-if="errorMessage" class="bulk-error-message">
        <DotSeparator
          :key="validationPassed"
          :color="validationPassed ? 'var(--color-primary)' : 'var(--color-error)'"
          size="8px"
          margin="0 12px 0 0"
        />
        {{ errorMessage }}
      </AppText>
      <MainTable
        :columns="addClientColumns"
        :data="clients"
        is-editable
        :style="isAddClientsLoading ? `pointer-events: none;` : ''"
        :full-height="!isDataEnough"
        @edit-complete="onEditComplete"
      >
        <template #column="{ column }">
          <AppText type="uppercase">
            {{ column.title }}
          </AppText>
        </template>
        <template #cell="{ item, row, column }">
          <div class="d-flex justify-content-between align-items-center w-100 h-100">
            <AppTooltip
              v-if="row.errors[column.name]"
              class="w-100 h-100"
              :is-enabled="!isEmailFieldIsEmpty(column.name, item) || !isNameFieldIsEmpty(column.name, item)"
            >
              <template #text>
                <span class="inner-text">
                  {{ row.errors[column.name] }}
                </span>
              </template>

              <AppText :class="{ 'is-error': row.errors[column.name] }" class="inner-text">
                {{ item }}
                <template v-if="isEmailFieldIsEmpty(column.name, item)">
                  {{ t('merchantClients.clientCreateModal.error.emailRequired') }}
                </template>
                <template v-if="isNameFieldIsEmpty(column.name, item)">
                  {{ t('merchantClients.clientCreateModal.error.nameRequired') }}
                </template>
              </AppText>
            </AppTooltip>
            <AppText v-else-if="column.name === 'id'" class="inner-text">
              {{ row.isDuplicated ? '-' : item + 1 }}
            </AppText>
            <div v-else-if="column.name === 'actions'" class="d-flex align-items-center justify-content-center w-100">
              <DotSeparator v-if="row.isDuplicated" color="#a9a9a9" size="8px" margin="0px" />
              <AppIcon
                v-else
                style="cursor: pointer;"
                name="trash"
                :opacity="0.4"
                size="16px"
                class="trash-icon"
                @click="removeClient(row.id)"
              />
            </div>
            <div v-else class="ellipsis">
              <AppText class="inner-text">
                {{ item }}
              </AppText>
            </div>
          </div>
        </template>
      </MainTable>
    </template>

    <FSpacer />

    <div v-if="isMounted" class="footer">
      <FButton
        v-if="!isPreview"
        no-margin
        size="small"
        :loading="isClientProcessing"
        @click="previewClients"
      >
        {{ t('common.preview') }}
      </FButton>

      <template v-if="isPreview">
        <FButton
          class="button__back"
          type="plain"
          no-margin
          size="small"
          @click="goBack"
        >
          <AppIcon name="arrow-bracket" class="rotate-270" size="18px" is-img-tag />
          {{ t('common.back') }}
        </FButton>

        <FButton
          v-if="validationPassed && validClientsLenght > 0"
          class="button__add"
          :loading="isAddClientsLoading"
          no-margin
          size="small"
          @click="onAddClients"
        >
          <AppIcon name="download" size="18px" fill="var(--color-white)" />
          {{ addButtonText }}
        </FButton>
      </template>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref, watch,
} from 'vue';

import DotSeparator from '@/components/DotSeparator.vue';
import MainTable from '@/components/MainTable';
import AppTooltip from '@/components/AppTooltip.vue';

import { i18n } from '@/plugins/localization';
import { useModel } from '@/composables/useModel';

import { addClientColumns } from '../data';
import { bulkAddClients } from '../api';

import { useClientsParsing } from './useClientsParsing';

export default {
  name: 'CreateBulk',
  components: {
    MainTable,
    DotSeparator,
    AppTooltip,
  },
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update-data', 'update:is-preview'],
  setup(props, { emit }) {
    const { t } = i18n.global;
    const { value: isPreviewProp } = useModel(props, emit, { prop: 'is-preview' });
    const {
      clients,
      data,
      isBulkInputValid,
      isPreviewPropInner,
      validationPassed,
      previewData,
      errorMessage,
      processErrors,
      removeClient,
    } = useClientsParsing();

    const isClientProcessing = ref(false);

    const placeholder = t('merchantClients.clientCreateModal.label.bulkMemo');

    const previewClients = () => {
      if (!data.value.length) {
        isBulkInputValid.value = false;
      }
      if (isBulkInputValid.value) {
        isClientProcessing.value = true;
        previewData(isPreviewProp);
        isClientProcessing.value = false;
      }
    };

    const onEditComplete = () => {
      processErrors();
    };

    const isAddClientsLoading = ref(false);
    const onAddClients = async () => {
      isAddClientsLoading.value = true;
      const { isSuccess, responseError } = await bulkAddClients(clients.value);
      if (isSuccess) {
        emit('update-data');
        goBack();
        data.value = '';
      } else {
        processErrors(responseError);
      }
      isAddClientsLoading.value = false;
    };

    const goBack = () => {
      isPreviewProp.value = false;
      isPreviewPropInner.value = false;
      clients.value = [];
    };

    const validClientsLenght = computed(() => clients.value.filter((item) => !item.isDuplicated).length);
    const addButtonText = computed(() => `${t('common.importAction')} ${validClientsLenght.value} ${t('common.client', validClientsLenght.value)}`);

    const isMounted = ref(false);

    onMounted(() => {
      isMounted.value = true;
    });

    watch(() => isPreviewPropInner.value, (val) => {
      isPreviewProp.value = val;
    });

    const isDataEnough = computed(() => clients.value.length >= 12);
    const isEmailFieldIsEmpty = (columnName, item) => columnName === 'email' && !item;
    const isNameFieldIsEmpty = (columnName, item) => columnName === 'name' && !item;

    return {
      isPreviewProp,

      data,
      addClientColumns,
      placeholder,
      previewData,
      onAddClients,
      clients,
      errorMessage,
      validationPassed,
      goBack,
      removeClient,

      addButtonText,
      validClientsLenght,

      isBulkInputValid,
      isAddClientsLoading,
      previewClients,
      onEditComplete,

      isMounted,
      isClientProcessing,
      isDataEnough,
      isEmailFieldIsEmpty,
      isNameFieldIsEmpty,
    };
  },
};
</script>

<style lang="scss" scoped>
.bulk {
  .bulk-textarea {
    margin-bottom: 0;
    :deep(.el-textarea__inner) {
      min-height: 350px !important;
    }
  }

  :deep(.table__wrap) {
    height: 370px;
    display: block;

    .table {
      table-layout: fixed;
    }

    .table__head {
      .table__head__column {
        height: 32px;
        .table__column__cell {
          height: 32px;
          padding: 0 20px;
        }
      }
    }
    .table__body {
      .table__row__cell {
        height: 36px
      }
    }
  }

  :deep(.is-row-duplicated) {
    background: #F2F2F2;
    opacity: 0.5;
    &:hover {
      .table__row__cell {
        background: #F2F2F2;
      }
    }
  }
  :deep(.table__row__cell-wrap) {
    padding: 0 !important;
    .inner-text {
      padding: 0 20px !important;
    }
  }

  .footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.is-error {
  color: var(--color-error);
}

.bulk-error-message {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.trash-icon {
  &:hover {
    opacity: 1;

    :deep(path) {
      fill: var(--color-error);
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
