<template>
  <div class="single">
    <ActionForm
      :action="addClient"
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      @success="onSuccess"
    >
      <template #default="{ errors, action }">
        <AppText
          :line-height="1.5"
          opacity="0.4"
          variant="div"
          mt="4px"
          mb="20px"
        >
          {{ t('merchantClients.clientCreateModal.label.description') }}
        </AppText>
        <div class="form-wrapper">
          <FInput
            v-model="requestFields.email"
            has-prefix
            placeholder="example@gmail.com"
            :validation-error="errors.email"
          >
            <template #prefix>
              <AppIcon
                name="mail"
                opacity="0.4"
                size="18px"
                is-img-tag
              />
            </template>
          </FInput>
          <FInput
            v-model="requestFields.fullName"
            class="client-name"
            has-prefix
            :placeholder="t('merchantClients.clientCreateModal.label.clientName')"
            :validation-error="errors.fullName"
            :maxlength="25"
          >
            <template #prefix>
              <AppIcon
                name="user"
                opacity="0.4"
                size="18px"
                is-img-tag
              />
            </template>
          </FInput>
        </div>
        <AppText
          v-if="checkErrors(errors)"
          opacity="0.4"
          variant="div"
          mt="13px"
        >
          {{ t('merchantClients.clientCreateModal.label.clientNameNote') }}
        </AppText>
        <div class="spacer" />

        <div class="d-flex align-items-center">
          <FButton
            no-margin
            size="small"
            :loading="isLoading"
            style="margin-right: 28px;"
            @click="action"
          >
            <AppIcon name="plus" size="18px" fill="var(--color-white)" />
            {{ t('merchantClients.clientCreateModal.title.add') }}
          </FButton>

          <div class="flex-center">
            <AppIcon name="info-circle-medium" size="18px" opacity="0.4" style="margin-right: 8px;" /> <AppText color="var(--color-black-04)">
              {{ t('merchantClients.clientCreateModal.label.bottomDescription') }}
            </AppText>
          </div>
        </div>
      </template>
    </ActionForm>
  </div>
</template>

<script>

import { ActionForm, useValidation, yup } from '@/validation';
import { emailRule, nameRule } from '@/validation/rules';

import { clientsApi } from '../api';

export default {
  name: 'CreateSingle',
  components: {
    ActionForm,
  },
  emits: ['update-data'],
  setup(props, { emit }) {
    const { isLoading, addClient } = clientsApi();
    const { clearState, initState } = useValidation();

    const fields = {
      email: '',
      fullName: '',
    };

    const validationSchema = yup.object().shape({
      email: emailRule,
      fullName: nameRule,
    });

    const { requestFields } = initState(fields);

    const onSuccess = () => {
      emit('update-data');
      clearState();
    };

    const checkErrors = (errors) => Object.keys(errors).length === 0;

    return {
      addClient,
      isLoading,

      validationSchema,
      requestFields,
      onSuccess,
      checkErrors,
    };
  },
};
</script>

<style lang="scss" scoped>
.single {
  .form-wrapper {
    width: 420px;
  }

  .client-name {
    margin-top: 20px;
  }
}

.spacer {
  margin-bottom: 189px;
}
</style>
