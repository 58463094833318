import { ref } from 'vue';

import { useFetch } from '@/api';

export const clientsApi = () => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const isLoading = ref(false);

  const addClient = async ({ fullName, email }) => {
    isLoading.value = true;
    const { isSuccess, errorObject } = await axiosWrapper({
      type: 'post',
      url: 'merchants/clients',
      params: { fullName, email },
      hasForceNotification: true,
    });
    isLoading.value = false;

    if (isSuccess) {
      successHandler(t('merchantClients.notification.addClientSuccess'));
    }

    return { isSuccess, errorObject };
  };

  return {
    isLoading,
    addClient,
  };
};

export const setClientName = async ({ clientId, fullName }) => {
  const { axiosWrapper, t } = useFetch();

  const { isSuccess } = await axiosWrapper({
    type: 'patch',
    url: '/merchants/clients/name',
    params: { clientId, fullName },
    successMessage: t('merchantClients.notification.changeClientNameSuccess'),
  });

  return { isSuccess };
};

export const bulkAddClients = async (rawClients) => {
  const responseDublicatedAmount = rawClients
    .filter((client) => client.isResponseDuplicated)
    .length;

  const clients = rawClients
    .filter((client) => !client.isDuplicated || !client.isResponseDuplicated)
    .map((client) => ({
      fullName: client.name,
      email: client.email,
    }));

  const { axiosWrapper, successHandler, t } = useFetch();
  const { isSuccess, error } = await axiosWrapper({
    type: 'post',
    url: '/merchants/clients/bulk',
    params: { clients },
  });

  if (isSuccess) {
    const success = t('merchantClients.notification.bulkAddClientsSuccess');
    const successAndHasDublication = t('merchantClients.notification.bulkAddClientsWithDublicationsSuccess', { amount: responseDublicatedAmount });

    const successMessage = responseDublicatedAmount ? successAndHasDublication : success;
    successHandler(successMessage);
  }

  const responseError = isSuccess ? error : JSON.parse(error.data.message);

  return { isSuccess, responseError };
};
