import { computed, ref, watch } from 'vue';

import { i18n } from '@/plugins/localization';
import { STRING_WITHOUT_SPACES } from '@/validation/rules';

const getErrorMessages = ({ name, email }) => {
  const { t } = i18n.global;
  const errors = { name: '', email: '' };

  if (!name) {
    errors.name = t('merchantClients.clientCreateModal.error.syntax');
  }

  if (name?.length < 1) {
    errors.name = t('merchantClients.clientCreateModal.error.min1Character');
  }

  if (!email) {
    errors.email = t('merchantClients.clientCreateModal.error.notAnEmail');
  }

  if (email?.length > 50) {
    errors.email = t('merchantClients.clientCreateModal.error.max50Characters');
  }

  return errors;
};
const emailRegexp = /^[\w-.+]+\w+@([\w-]+\.)+[\w-]+$/g;

export const useClientsParsing = () => {
  const { t } = i18n.global;

  const isPreviewPropInner = ref(false);
  const isBulkInputValid = ref(true);
  const clients = ref([]);
  const data = ref('');
  const inputErrorsAmount = computed(() => clients.value
    .reduce((acc, client) => {
      acc.push(client.errors.name || client.errors.email);
      return acc;
    }, [])
    .filter((el) => el).length);

  const dublicationsAmount = computed(() => clients.value.filter((el) => el.isDuplicated).length);
  const dublicationsResponseAmount = computed(() => clients.value.filter((el) => el.isResponseDuplicated).length);
  const validationPassed = computed(() => !inputErrorsAmount.value);

  const errorMessage = computed(() => {
    const result = [];
    if (inputErrorsAmount.value) {
      const value = inputErrorsAmount.value;
      result.push(`${value} ${t('merchantClients.clientCreateModal.error.inputError', value)}`);
    }
    if (dublicationsAmount.value - dublicationsResponseAmount.value) {
      const value = dublicationsAmount.value - dublicationsResponseAmount.value;
      result.push(`${value} ${t('merchantClients.clientCreateModal.error.dublicates', value)}`);
    }
    if (dublicationsResponseAmount.value) {
      const value = dublicationsResponseAmount.value;
      result.push(`${dublicationsResponseAmount.value} ${t('merchantClients.clientCreateModal.error.entriesFound', value)}`);
    }
    return result.join(', ');
  });

  const parseData = () => {
    const values = data.value.trim().split(';');

    if (!values[values.length - 1]) {
      values.pop();
    }

    if (values.length > 100) {
      return [];
    }

    return values.reduce((acc, contact, index) => {
      const res = contact.split(':');
      const name = res[0].replaceAll('\n', '').replaceAll('<', '').replaceAll('>', '').match(STRING_WITHOUT_SPACES)?.[0];
      const email = res[1]?.match(emailRegexp)?.[0];

      acc.push({
        id: index,
        number: index,
        name,
        email,
        initialValue: { name: res[0], email: res[1] },
      });

      return acc;
    }, []);
  };

  const processClients = (responseError) => {
    const duplications = {};
    let numberIndex = 0;

    const isClientExists = (clientEmail) => clients.value.filter(({ email }) => email && email === clientEmail).length > 1;

    return clients.value.map((client) => {
      const name = client.name?.match(STRING_WITHOUT_SPACES)?.[0];
      const email = client.email?.match(emailRegexp)?.[0];
      const errors = getErrorMessages({ name, email });

      const isExists = isClientExists(client.email);
      const isDuplicated = Boolean(duplications[client.email]);

      if (isExists) {
        duplications[client.email] = true;
      }

      let foundDatabaseDuplication = false;
      if (responseError) {
        const responseIndex = responseError.findIndex((err) => err.email === client.email);
        foundDatabaseDuplication = responseIndex > -1;
      }

      const isDuplication = isDuplicated || foundDatabaseDuplication;
      if (!isDuplication) {
        numberIndex++;
      }

      return {
        ...client,
        number: isDuplication ? '-' : numberIndex,
        errors,
        isDuplicated: isDuplication,
        isResponseDuplicated: foundDatabaseDuplication,
        initialValue: {
          name: client.name || client.initialValue.name,
          email: email || client.initialValue.email,
        },
      };
    });
  };

  const previewData = () => {
    if (!data.value) {
      isBulkInputValid.value = false;
      return;
    }

    clients.value = parseData();
    clients.value = processClients();

    if (clients.value?.length) {
      isPreviewPropInner.value = true;
    } else {
      isBulkInputValid.value = false;
    }
  };

  const processErrors = (responseError) => {
    clients.value = processClients(responseError);
  };

  const removeClient = (id) => {
    clients.value.splice(clients.value.findIndex((client) => client.id === id), 1);
    if (!clients.value.length) {
      data.value = '';
      isPreviewPropInner.value = false;
    }
    clients.value = processClients();
  };

  watch(data, () => {
    if (data.value.length > 0) {
      isBulkInputValid.value = true;
    }
  });

  return {
    clients,
    data,
    errorMessage,
    isBulkInputValid,
    validationPassed,
    isPreviewPropInner,
    previewData,
    removeClient,
    processErrors,
  };
};
