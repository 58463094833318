import { reactive, ref } from 'vue';

import { TABLE_TYPES, statusCellOptions } from '@/common/tableTypes';

export const columns = [
  {
    name: 'clientId',
    isShow: true,
    isPermanent: true,
    type: TABLE_TYPES.ID,
    width: 180,
    suppressSizeToFit: true,
  },
  {
    name: 'clientName',
    type: TABLE_TYPES.PERSON,
    isShow: true,
    isPermanent: true,
    width: 300,
    suppressSizeToFit: true,
  },
  {
    name: 'clientEmail',
    isShow: true,
    isPermanent: true,
    width: 300,
  },
  {
    name: 'paymentsCount',
    isShow: true,
    width: 150,
  },
  {
    name: 'revenue',
    type: TABLE_TYPES.CURRENCY,
    isShow: true,
    width: 150,
  },
  {
    name: 'lastPayment',
    type: TABLE_TYPES.DATE_TIME,
    isShow: true,
    width: 240,
  },
  {
    name: 'createdAt',
    type: TABLE_TYPES.DATE_TIME,
    isShow: false,
    width: 240,
  },
  {
    ...statusCellOptions,
  },
];

export const statuses = [
  { value: 'ALL' },
  { value: 'OPEN' },
  { value: 'DO_NOT_DISTURB' },
];

export const filters = reactive([
  {
    label: 'Date Range',
    type: 'DATE_RANGE',
    field: ['dateFrom', 'dateTo'],
    modelValue: ref([]),
  },
]);

export const creationTypes = [
  { value: 'single' },
  { value: 'bulk' },
];

export const addClientColumns = [
  {
    title: '#', name: 'number', isShow: true, width: 50,
  },
  {
    title: 'name', name: 'name', isShow: true, isEditable: true, width: 170,
  },
  {
    title: 'email', name: 'email', isShow: true, isEditable: true, width: 300,
  },
  {
    title: '', name: 'actions', isShow: true, width: 60,
  },
];
