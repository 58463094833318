<template>
  <TransitionWrapper>
    <div class="main-content-body">
      <FiltersHeader
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        :statuses="statuses"
        :filters="filters"
      >
        <template #status-aside>
          <AppTooltip
            width="250px"
            text-align="left"
            cursor="default"
            style="margin-left: 20px;"
          >
            <template #text>
              {{ t('merchantClients.label.dndStatus') }}
            </template>
            <AppIcon name="info-circle-big" size="18px" :opacity="0.4" is-img-tag />
          </AppTooltip>
        </template>
        <template #top-append>
          <AddButton @click="isCreateClientModalOpen = true">
            {{ t('merchantClients.label.createButton') }}
          </AddButton>
        </template>
      </FiltersHeader>

      <PaginationTable
        :module-name="moduleName"
        :api-path="apiPath"
        :columns="columns"
        disabled-status="INACTIVE"
        @row-click="onRowClick"
      />
    </div>

    <CreateClientModal
      v-model="isCreateClientModalOpen"
      @update-data="postProcessAfterCreation"
    />

    <ClientModal
      v-model="isClientModalOpen"
      :client="selectedClient"
      @update-data="getFreshData"
    />
  </TransitionWrapper>
</template>

<script>
import { reactive, ref } from 'vue';

import AddButton from '@/components/Buttons/AddButton.vue';
import FiltersHeader from '@/components/Modules/DataTable/FiltersHeader.vue';
import PaginationTable from '@/components/Modules/DataTable/PaginationTable.vue';
import AppTooltip from '@/components/AppTooltip.vue';
import TransitionWrapper from '@/components/Animation/TransitionWrapper.vue';

import { usePagination } from '@/composables/usePagination';
import { MODULES_NAMES } from '@/common/modules';
import { keys, useKeyboardEvent } from '@/composables/useKeyboardEvent';
import { useTablesStore } from '@/store';

import ClientModal from './ClientModal';
import CreateClientModal from './CreateClientModal';
import { columns, filters, statuses } from './data';

export default {
  name: 'MerchantClients',
  components: {
    CreateClientModal,
    ClientModal,
    FiltersHeader,
    PaginationTable,
    TransitionWrapper,
    AddButton,
    AppTooltip,
  },
  setup() {
    const moduleName = MODULES_NAMES.MERCHANT_CLIENTS;
    const apiPath = '/merchants/clients/elastic';

    const { getData, items } = usePagination(apiPath, {
      eventName: 'update-clients-page',
      moduleName,
      filters,
    });

    getData();

    const isCreateClientModalOpen = ref(false);
    const isClientModalOpen = ref(false);

    const selectedClient = reactive({});

    const onRowClick = (row) => {
      Object.assign(selectedClient, row);
      isClientModalOpen.value = true;
    };

    const getFreshData = async () => {
      await getData({ isFreshResponse: true, shouldResetRequestState: true });
      if (selectedClient.id) {
        const updatedClient = items.value.find((el) => el.id === selectedClient.id);
        Object.assign(selectedClient, updatedClient);
      }
    };

    useKeyboardEvent(keys.BACKQUOTE, () => { isCreateClientModalOpen.value = true; });

    const tableStore = useTablesStore();
    const postProcessAfterCreation = () => {
      tableStore.resetPageDateFilters(moduleName);
      setTimeout(() => {
        getFreshData();
      }, 0);
    };

    return {
      moduleName,
      apiPath,

      columns,
      statuses,
      filters,

      isCreateClientModalOpen,
      isClientModalOpen,

      selectedClient,

      getFreshData,
      onRowClick,
      postProcessAfterCreation,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.table__body .table__row__cell) {
  cursor: pointer;
}
</style>
